/*
进行local数据存储管理的工具模块
 */
import store from "store";
const USER_KEY = "User_Left_Nav";
const QQ_USER_KEY = "QQ_USER_KEY";
const WEB_MODEL_KEY = "WEB_MODEL_KEY";
export default {
  /*
  保存user
   */
  saveUser(user) {
    // localStorage.setItem(USER_KEY, JSON.stringify(user))
    store.set(USER_KEY, user);
  },
  /*
  读取user
   */
  getUser() {
    // return JSON.parse(localStorage.getItem(USER_KEY) || '{}')
    return store.get(USER_KEY) || {};
  },
  /*
  删除user
   */
  removeUser() {
    // localStorage.removeItem(USER_KEY)
    store.remove(USER_KEY);
  },
  // ----------------------------------------分割-------------------------------------------------
  saveQQUser(user) {
    //QQ登陆者信息存储
    store.set(QQ_USER_KEY, user);
  },
  getQQUser() {
    //QQ登陆者信息读取
    return store.get(QQ_USER_KEY) || {};
  },
  removeQQUser() {
    //QQ登陆者信息删除
    // localStorage.removeItem(USER_KEY)
    store.remove(QQ_USER_KEY);
  },
  // ----------------------------------------分割-------------------------------------------------
  saveWebModel(info) {
    //博客Model信息存储
    store.set(WEB_MODEL_KEY, info);
  },
  getWebModel() {
    //博客Model信息读取
    return store.get(WEB_MODEL_KEY) || false;
  },
};
