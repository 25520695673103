import React, {useState, useEffect} from "react";
import {Row, Col, List, Icon, message, Spin, Tag, Pagination} from "antd";
import {getTableData} from "../request/request";
import SearchInputDounce from "@/common/SearchInputDounce/index";
import BackTopModule from "@/main-page/common/back-top";
import CountUp from "react-countup";
import LazyLoad from "react-lazyload";
import Header from "@/components/Header";
import Author from "@/components/Author";
import Footer from "@/components/Footer";
import EasyLike from "@/components/EasyLike";
import LinkChange from "@/components/LinkChange";
import {withRouter} from "react-router-dom";
import store from "@/store/index"; //redux使用

import marked from "marked";
import hljs from "highlight.js";
import "highlight.js/styles/monokai-sublime.css";
import "@/style/page/common.css";
import "./style/index.less";
const renderer = new marked.Renderer();
marked.setOptions({
  renderer: renderer,
  gfm: true, //启动类似Github样式的Markdown,填写true或者false
  pedantic: false, //只解析符合Markdown定义的，不修正Markdown的错误。填写true或者false
  sanitize: false, //原始输出，忽略HTML标签，这个作为一个开发人员，一定要写flase
  tables: true, //支持Github形式的表格，必须打开gfm选项
  breaks: true, //支持Github换行符，必须打开gfm选项，填写true或者false
  smartLists: true, //优化列表输出，这个填写ture之后，你的样式会好看很多，所以建议设置成ture
  smartypants: true,
  highlight: function (code) {
    return hljs.highlightAuto(code).value;
  },
});

const ProjectBlog = ({history}) => {
  const [pageCode, setPageCode] = useState(1); // 页码
  const [loading, setLoading] = useState(false); // loading状态
  const [tableData, setTableData] = useState([]); // table数据
  const [tableDataCount, setTableDataCount] = useState(); // table数据总条数

  const [searchValue, setSearchValue] = useState(""); // 搜索框值

  // redux
  const [authorStyle, setAuthorStyle] = useState(store.getState().defstyle);
  const [drawerStyle, setDrawerStyle] = useState(store.getState().drawerstyle);
  // 订阅Redux的状态
  store.subscribe(() => {
    setAuthorStyle(store.getState().defstyle);
    setDrawerStyle(store.getState().drawerstyle);
  });
  useEffect(() => {
    document.addEventListener("visibilitychange", function () {
      var isHidden = document.hidden;
      if (isHidden) {
        document.title = '404!!!页面丢失(￣▽￣)"';
      } else {
        document.title = "嘤嘤嘤，你回来了啊(ಥ _ ಥ)";
        setTimeout(() => {
          document.title = "实战页 | Youngster_yj的学习记录";
        }, 3000);
      }
    });
    document.title = "实战页 | Youngster_yj的学习记录";
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTableData([]);
    // 重构接口请求
    commonGetTableData();
  }, [pageCode, searchValue]);

  const searchInput = value => {
    setPageCode(1);
    setSearchValue(value);
  };

  // 公共函数 获取table数据
  const commonGetTableData = async () => {
    try {
      setLoading(true);
      let obj = {page: pageCode, search: searchValue, groupType: 2};
      const data = await getTableData(obj);
      if (data) {
        setTableData(data.all);
        setTableDataCount(data.count);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  // 页面跳转
  const toHistory = value => {
    history.push("/blog/" + value);
  };
  return (
    <div
      className="project-blog"
      style={
        drawerStyle
          ? {
              paddingLeft: "256px",
              overflow: "hidden",
              transition: "all linear .3s",
              position: " fixed",
              width: "170%",
            }
          : null
      }>
      <Header />
      <BackTopModule authorStyle={authorStyle} />
      <Row className="comm-main" type="flex" justify="center" style={{paddingTop: "3.2rem"}}>
        <Col
          className="comm-left"
          xs={24}
          sm={24}
          md={16}
          lg={18}
          xl={14}
          style={{backgroundColor: "rgba(255,255,255,.4)"}}>
          <Spin tip="加载中..." spinning={loading}>
            <List
              header={
                <Row>
                  <Col xs={12} sm={14} md={15} lg={17} xl={17}>
                    <div
                      style={{
                        fontWeight: "bold",
                        paddingLeft: 20,
                        lineHeight: "32px",
                      }}>
                      实战积累 <span style={{color: "red"}}>{tableDataCount ? tableDataCount : "~"}</span> 篇
                    </div>
                  </Col>
                  <Col xs={11} sm={9} md={8} lg={6} xl={6}>
                    <SearchInputDounce placeholder="搜索实战内容" getvalue={searchInput} />
                  </Col>
                  <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                </Row>
              }
              itemLayout="vertical"
              dataSource={tableData}
              renderItem={(item, index) => {
                const html1 = marked(item.introducemd); //let html=marked(mdcontent) 的方式转换md 内容为html 并进行渲染
                return (
                  <List.Item key={index} className="cssnicehover">
                    <LazyLoad height={200} offset={-200}>
                      <div className={window.screen.width >= 770 ? "cssnice1" : "cssnice"}>
                        <div className="list-title">
                          <span style={authorStyle ? {color: "deeppink"} : null}>
                            <span
                              style={{cursor: "pointer"}}
                              onClick={() => {
                                toHistory(item._id);
                              }}>
                              {item.articleTitle}
                            </span>
                          </span>
                        </div>
                        <div className="list-icon">
                          {item.topblog ? (
                            <span>
                              <Tag color="red" style={{margin: 0}}>
                                置顶
                              </Tag>
                            </span>
                          ) : null}
                          <span>
                            <Icon type="calendar" style={{color: "lightseagreen"}} /> {item.showDate}
                          </span>
                          <span>
                            <Icon type="login" style={{color: "#ff7d00"}} />{" "}
                            {item.sourceType === undefined ? "实战分享" : item.sourceType}
                          </span>
                          <span>
                            <Icon type="fire" style={{color: "red"}} />{" "}
                            {item.fire ? (
                              <CountUp start={0} end={item.fire} duration={2} style={{padding: "0px"}} />
                            ) : (
                              "暂无浏览"
                            )}
                          </span>
                        </div>
                        <div className="list-context" dangerouslySetInnerHTML={{__html: html1}}></div>
                        <div
                          style={{
                            textAlign: "right",
                            marginRight: 20,
                            fontSize: 15,
                            color: "#1e90ff",
                            position: "relative",
                          }}>
                          <span
                            style={{
                              margin: 0,
                              left: ".5rem",
                              position: "absolute",
                            }}>
                            {item.author ? <Tag color="#f50">作者:{item.author}</Tag> : null}
                            {item.isenter || item.isenter === undefined ? null : (
                              <Tag
                                color="#87d068"
                                onClick={() => {
                                  message.warning("请登陆后访问");
                                }}>
                                文章加密
                              </Tag>
                            )}
                          </span>
                          <span
                            style={{cursor: "pointer"}}
                            onClick={() => {
                              toHistory(item._id);
                            }}>
                            <Icon type="arrows-alt" style={{marginRight: 10}} />
                            <span>查看全文 》</span>
                          </span>
                        </div>
                      </div>
                    </LazyLoad>
                  </List.Item>
                );
              }}
            />

            {tableDataCount > 10 && (
              <LazyLoad height={200} offset={-10}>
                <Pagination
                  showQuickJumper
                  defaultCurrent={1}
                  total={tableDataCount}
                  onChange={current => setPageCode(current)}
                  className="cssnice3"
                  current={pageCode}
                  style={{textAlign: "center", padding: ".5rem 0 .5rem"}}
                />
              </LazyLoad>
            )}
          </Spin>
        </Col>
        <Col xs={0} sm={0} md={7} lg={5} xl={4}>
          <Author  />
          <EasyLike />
          <LinkChange />
        </Col>
      </Row>
      <Footer />
    </div>
  );
};

export default withRouter(ProjectBlog);
