import React from "react";
import {BackTop, Icon} from "antd";
const BackTopModule = ({authorStyle}) => {
  return (
    <BackTop>
      <div className="ant-back-top-inner">
        <Icon type="rocket" style={authorStyle ? {color: "lightpink"} : null} />
      </div>
    </BackTop>
  );
};

export default BackTopModule;
