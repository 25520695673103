import React from "react";
import {Route, Switch} from "react-router-dom";
import Home from "./home";

const Index = () => {
  return (
    <Switch>
      {/* 主页 */}
      <Route path="/life" component={Home} />
    </Switch>
  );
};

export default Index;
