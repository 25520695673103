import React, {useState, useEffect} from "react";
import {Row, Col, List, Icon, message, Spin, Typography, Pagination} from "antd";
import {getTableData} from "../request/request";
import BackTopModule from "@/main-page/common/back-top";
import SearchInputDounce from "@/common/SearchInputDounce/index";
import CountUp from "react-countup";
import LazyLoad from "react-lazyload";
import Header from "@/components/Header";
import Author from "@/components/Author";
import Footer from "@/components/Footer";
import EasyLike from "@/components/EasyLike";
import MyInfo from "@/components/MyInfo";
import {withRouter} from "react-router-dom";
import store from "@/store/index"; //redux使用

import marked from "marked";
import hljs from "highlight.js";
import "highlight.js/styles/monokai-sublime.css";
import "@/style/page/life.css";
import "./style/index.less";
const renderer = new marked.Renderer();
marked.setOptions({
  renderer: renderer,
  gfm: true, //启动类似Github样式的Markdown,填写true或者false
  pedantic: false, //只解析符合Markdown定义的，不修正Markdown的错误。填写true或者false
  sanitize: false, //原始输出，忽略HTML标签，这个作为一个开发人员，一定要写flase
  tables: true, //支持Github形式的表格，必须打开gfm选项
  breaks: true, //支持Github换行符，必须打开gfm选项，填写true或者false
  smartLists: true, //优化列表输出，这个填写ture之后，你的样式会好看很多，所以建议设置成ture
  smartypants: true,
  highlight: function (code) {
    return hljs.highlightAuto(code).value;
  },
});
const {Paragraph} = Typography;

const ProjectBlog = ({history}) => {
  const [pageCode, setPageCode] = useState(1); // 页码
  const [loading, setLoading] = useState(false); // loading状态
  const [tableData, setTableData] = useState([]); // table数据
  const [tableDataCount, setTableDataCount] = useState(); // table数据总条数

  const [searchValue, setSearchValue] = useState(""); // 搜索框值
  const [randomArr, setRandomArr] = useState([]); // 生成不重复随机数

  // redux
  const [authorStyle, setAuthorStyle] = useState(store.getState().defstyle);
  const [drawerStyle, setDrawerStyle] = useState(store.getState().drawerstyle);
  // 订阅Redux的状态
  store.subscribe(() => {
    setAuthorStyle(store.getState().defstyle);
    setDrawerStyle(store.getState().drawerstyle);
  });
  useEffect(() => {
    document.addEventListener("visibilitychange", function () {
      var isHidden = document.hidden;
      if (isHidden) {
        document.title = '404!!!页面丢失(￣▽￣)"';
      } else {
        document.title = "嘤嘤嘤，你回来了啊(ಥ _ ಥ)";
        setTimeout(() => {
          document.title = "生活页 | Youngster_yj的学习记录";
        }, 3000);
      }
    });
    document.title = "生活页 | Youngster_yj的学习记录";
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTableData([]);
    // 重构接口请求
    commonGetTableData();
  }, [pageCode, searchValue]);

  const random = value => {
    //生成不重复随机数
    var arr = [];
    for (var i = 0; i < value.length; i++) {
      var arrNum = parseInt(Math.random() * 74);
      var flag = true;
      for (var j = 0; j <= arr.length; j++) {
        if (arrNum === arr[j]) {
          flag = false;
          break;
        }
      }
      if (flag) {
        arr.push(arrNum);
      } else {
        i--;
      }
    }
    setRandomArr(arr);
  };

  const searchInput = value => {
    setPageCode(1);
    setSearchValue(value);
  };

  // 公共函数 获取table数据
  const commonGetTableData = async () => {
    try {
      setLoading(true);
      let obj = {page: pageCode, search: searchValue, groupType: 3};
      const data = await getTableData(obj);
      if (data) {
        setTableData(data.all);
        setTableDataCount(data.count);
        random(data.all);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  // 页面跳转
  const toHistory = value => {
    history.push("/blog/" + value);
  };
  return (
    <div
      className="life-blog"
      style={
        drawerStyle
          ? {
              paddingLeft: "256px",
              overflow: "hidden",
              transition: "all linear .3s",
              position: " fixed",
              width: "170%",
            }
          : null
      }>
      <Header />
      <BackTopModule authorStyle={authorStyle} />
      <Row className="comm-main" type="flex" justify="center" style={{paddingTop: "3.2rem"}}>
        <Col
          className="comm-left"
          xs={24}
          sm={24}
          md={16}
          lg={18}
          xl={14}
          style={{backgroundColor: "rgba(255,255,255,.4)"}}>
          <Spin tip="加载中..." spinning={loading}>
            <List
              header={
                <Row>
                  <Col xs={12} sm={14} md={15} lg={17} xl={17}>
                    <div
                      style={{
                        fontWeight: "bold",
                        paddingLeft: 20,
                        lineHeight: "32px",
                      }}>
                      生活趣事 <span style={{color: "red"}}>{tableDataCount ? tableDataCount : "~"}</span> 篇
                    </div>
                  </Col>
                  <Col xs={11} sm={9} md={8} lg={6} xl={6}>
                    <SearchInputDounce placeholder="搜索趣事内容" getvalue={searchInput} />
                  </Col>
                  <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                </Row>
              }
              itemLayout="vertical"
              dataSource={tableData}
              renderItem={(item, index) => {
                const html = marked(item.introducemd);
                return (
                  <List.Item key={index}>
                    <LazyLoad height={200} offset={-200}>
                      <div className={index % 2 === 0 ? "cssnice1" : "cssnice4"}>
                        <div className="borderbac">
                          <div
                            className="bacimg"
                            style={
                              window.screen.width >= 770
                                ? {
                                    height: "18rem",
                                    backgroundImage:
                                      "url('https://www.jsfan.net/some/lifeimg/life%20(" + randomArr[index] + ").jpg')",
                                  }
                                : {
                                    height: "10rem",
                                    backgroundImage:
                                      "url('https://www.jsfan.net/some/lifeimg/life%20(" + randomArr[index] + ").jpg')",
                                  }
                            }>
                            <div className="bacophover">
                              <div className="bacimg-title">
                                <Paragraph ellipsis style={{color: "#fff"}}>
                                  <span className="label" style={{color: "#fff"}}>
                                    {item.sourceType === undefined ? "生活分享" : item.sourceType}
                                  </span>
                                  <span style={{textShadow: "0 0 8px #fff"}}>{item.articleTitle}</span>
                                </Paragraph>
                              </div>
                              <div
                                className="bacimg-content"
                                style={window.screen.width >= 770 ? {marginTop: "5rem"} : null}
                                dangerouslySetInnerHTML={{__html: html}}></div>
                            </div>
                          </div>
                        </div>

                        <div className="botinfo">
                          <span style={{paddingRight: "1rem"}}>
                            <Icon type="calendar" style={{color: "lightseagreen"}} />
                            {item.showDate}
                          </span>
                          <span style={{paddingRight: "1rem"}}>
                            <Icon type="twitter" style={{color: "#ff00ff"}} />
                            {item.sourceType === undefined ? "生活分享" : item.sourceType}
                          </span>
                          {item.sourceType === "秃头日记" ? null : (
                            <span style={{paddingRight: "1rem"}}>
                              <Icon type="fire" style={{color: "red"}} />
                              {item.fire ? (
                                <CountUp start={0} end={item.fire} duration={2} style={{padding: "0px"}} />
                              ) : (
                                "暂无浏览"
                              )}
                            </span>
                          )}
                          {window.screen.width >= 770 ? (
                            <span style={{paddingRight: "1rem"}}>
                              <Icon type="user" style={{color: "lightseagreen"}} />
                              {item.author ? item.author : "Youngster_yj"}
                            </span>
                          ) : null}
                          {item.isenter || item.isenter === undefined ? null : (
                            <span style={{paddingRight: "1rem"}}>
                              <Icon type="eye-invisible" style={{color: "lightseagreen"}} />
                              文章加密
                            </span>
                          )}
                          {item.sourceType !== "秃头日记" ? (
                            <span
                              style={{float: "right", cursor: "pointer", color: "rgb(30, 144, 255)"}}
                              onClick={() => {
                                toHistory(item._id);
                              }}>
                              <Icon type="arrows-alt" style={{marginRight: 10}} />
                              <span>查看全文 》</span>
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </LazyLoad>
                  </List.Item>
                );
              }}
            />
            {tableDataCount > 10 && (
              <LazyLoad height={200} offset={-10}>
                <Pagination
                  showQuickJumper
                  defaultCurrent={1}
                  total={tableDataCount}
                  onChange={current => setPageCode(current)}
                  className="cssnice3"
                  current={pageCode}
                  style={{textAlign: "center", padding: ".5rem 0 .5rem"}}
                />
              </LazyLoad>
            )}
          </Spin>
        </Col>
        <Col xs={0} sm={0} md={7} lg={5} xl={4}>
          <Author  />
          <EasyLike />
          {/* <MyInfo /> */}
        </Col>
      </Row>
      <Footer />
    </div>
  );
};

export default withRouter(ProjectBlog);
