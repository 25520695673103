import React, {lazy, Suspense, Component} from "react";
// 新博客首页
import ArticleBlog from "./main-page/article-blog";
// 新实战页面
import ProjectBlog from "./main-page/project-blog";
// 新生活页面
import LifeBlog from "./main-page/life-blog";
import Blog from "./Blog";
import History from "./History";
import Message from "./Message";
import About from "./About";
import Picture from "./Picture";
import Socket from "./components/tool/Socket";
import Lantern from "./components/UIGlobal/Lantern";
import ReactAplayer from "react-aplayer";
import {Modal, Spin} from "antd";

import {
  Route, //路由
  BrowserRouter as Router, //所有的dom都包含在Router(根节点)
  Switch, //处理无匹配路由
} from "react-router-dom";

const Admin = lazy(() => import("./admin/Index.js"));
const Login = lazy(() => import("./admin/Login"));
const Other = lazy(() => import("./common/Other"));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false, //粘贴提示
      error: false, //复制是否错误
      isAlert: true, //是否继续提醒
      lanternIsShow: false, //灯笼是否展示
    };
  }

  copy = () => {
    //复制粘贴提示
    document.addEventListener("copy", e => {
      e.preventDefault();
      e.stopPropagation();
      // console.info('触发复制事件');
      this.setState(state => {
        if (state.isAlert) {
          this.setState({isOpen: true, error: false, isAlert: false});
        }
      });

      /** 
    返回一个Selection对象，表示用户选择的文本范围或光标的当前位置
    Selection.getRangeAt返回一个包含当前选区内容的区域对象
  */
      try {
        let text = window.getSelection().getRangeAt(0);
        let node = document.createElement("div");
        // cloneContents方法把范围（Range）的内容复制到一个DocumentFragment对象
        node.appendChild(window.getSelection().getRangeAt(0).cloneContents());
        /** 
       ClipboardEvent.clipboardData 属性保存了一个 DataTransfer 对象，这个对象可用于：
       描述哪些数据可以由 cut 和 copy 事件处理器放入剪切板，通常通过调用 setData(format, data) 方法；
       获取由 paste 事件处理器拷贝进剪切板的数据，通常通过调用 getData(format) 方法
    */
        if (e.clipboardData) {
          e.clipboardData.setData("text/html", node.innerHTML);
          e.clipboardData.setData("text/plain", text);
        } else if (window.clipboardData) {
          return window.clipboardData.setData("text", text);
        }
      } catch (error) {
        this.setState({
          error: true,
          isAlert: true,
        });
      }
    });
  };
  componentDidMount() {
    /* 长连接函数 */
    Socket();
    const {allowedCopy} = window.initBlog;
    allowedCopy && this.copy();
  }
  isOpen() {
    this.setState({
      isOpen: false,
    });
  }

  onInit = ap => {
    this.ap = ap;
  };

  render() {
    const props = {
      theme: "#F57F17",
      lrcType: 3,
      audio: [
        //http://www.jsfan.net:3001/search/suggest?keywords=花魁   寻找id
        //http://www.jsfan.net:3001/lyric?id=553753244 id找歌词
        {
          name: "微微",
          artist: "傅如乔",
          url: "https://www.jsfan.net/upload/微微.mp3",
          cover: "https://www.jsfan.net/upload/微微musicimg.jpg",
          // lrc: 'https://www.jsfan.net:3001/lyric?id=1433434738',
          theme: "#ebd0c2",
        },
        {
          name: "无人之岛",
          artist: "任然",
          url: "https://www.jsfan.net/upload/无人之岛.mp3",
          cover: "https://www.jsfan.net/upload/无人之岛img.jpg",
          // lrc: 'http://www.jsfan.net:3001/lyric?id=493735012',
          theme: "#ebd0c2",
        },
        {
          name: "你的答案",
          artist: "阿冗",
          url: "https://www.jsfan.net/upload/你的答案.mp3",
          cover: "https://www.jsfan.net/upload/你的答案阿.jpg",
          // lrc: 'http://www.jsfan.net:3001/lyric?id=1400256289',
          theme: "#ebd0c2",
        },
        {
          name: "权力的游戏",
          artist: "Ramin Djawadi", //作者名
          url: "https://www.jsfan.net/upload/权力的游戏.mp3",
          cover: "https://p1.music.126.net/ME34HLKlJtYGruIxomhIOQ==/7987951976023943.jpg",
          //   lrc: 'https://moeplayer.b0.upaiyun.com/aplayer/hikarunara.lrc',
          theme: "#ebd0c2",
        },
        {
          name: "刚好遇见你",
          artist: "李玉刚",
          url: "https://www.jsfan.net/upload/刚好遇见你.mp3",
          cover: "https://www.jsfan.net/upload/刚好遇见你.jpg",
          // lrc: 'http://www.jsfan.net:3001/lyric?id=459159104',
          theme: "#ebd0c2",
        },
        {
          name: "花魁",
          artist: "徐良",
          url: "https://www.jsfan.net/upload/花魁.mp3",
          cover: "https://www.jsfan.net/upload/花魁徐良.jpg",
          // lrc: 'http://www.jsfan.net:3001/lyric?id=553753244',
          theme: "#ebd0c2",
        },
        {
          name: "下山",
          artist: "要不要买菜",
          url: "https://www.jsfan.net/upload/下山.mp3",
          cover: "https://www.jsfan.net/upload/下山(要不要买菜).jpg",
          // lrc: 'http://www.jsfan.net:3001/lyric?id=1404885266',
          theme: "#ebd0c2",
        },
      ],
      fixed: true,
      autoplay: false,
      mini: true,
    };
    const {lanternIsShow} = this.props;
    return (
      <Suspense fallback={<Spin spinning style={{margin: "0 auto", display: "block"}} />}>
        <div>
          {/* 预留灯笼组件 */}
          {window.screen.width > 1250 && lanternIsShow ? <Lantern /> : null}

          {window.screen.width < 770 ? (
            <ReactAplayer {...props} onInit={this.onInit} onPlay={this.onPlay} onPause={this.onPause} />
          ) : null}

          <Router>
            <Switch>
              <Route path="/" exact component={ArticleBlog}></Route>
              <Route path="/project/" exact component={ProjectBlog}></Route>
              <Route path="/life/" component={LifeBlog}></Route>
              <Route path="/blog/:id" component={Blog}></Route>
              <Route path="/admin/" component={Admin}></Route>
              <Route path="/login/" component={Login}></Route>
              <Route path="/history/" component={History}></Route>
              <Route path="/message/" component={Message}></Route>
              <Route path="/about/" component={About}></Route>
              <Route path="/picture/" component={Picture}></Route>
              <Route component={Other}></Route>
            </Switch>
          </Router>

          <Modal visible={this.state.isOpen} centered={true} closable={false} footer={null} mask={false}>
            <div className="hideSweetAlert">
              <div className="icon success" style={{display: "block"}}>
                <div className="success-line">
                  <span className="line tip"></span>
                  <span className="line long"></span>
                </div>
                <div className="placeholder"></div>
                <div className="fix"></div>
              </div>
              <h2>{this.state.error ? "复制失败，请重试" : "复制成功"}</h2>
              <p>文章仅供各位大佬自用，原则上禁止转载，请尊重，谢过~</p>
              <button onClick={() => this.isOpen()}>{this.state.error ? "继续尝试" : "好的,不再提示"}</button>
            </div>
          </Modal>
        </div>
      </Suspense>
    );
  }
}

export default App;
