/*
 * @Author: rookie.cyj
 * @Date: 2021-01-10 16:51:06
 * @Last Modified by: rookie.CYJ
 * @Last Modified time: 2021-02-25 18:04:26
 */
const host = [
  {
    dev: "http://localhost:3000/blog-api",
    name: "devURL",
  },
  {
    cloud: "https://jsfan.net/blog-api",
    name: "cloudURL",
  },
  {
    cloud: "https://www.jsfan.net/blog-api",
    name: "cloudURL-2",
  },
];
export default host;
