import Axios from "axios";
import host from "@/config/host";
import {Modal} from "antd";
import storageUtils from "@/utils/storageUtils";
import memoryUtils from "@/utils/memoryUtils";
import cancelRequestArr from './cancelRequestArr';
// console.log("自定义环境变量", process.env.REACT_APP_ENV); //根据自定义环境变量实现请求地址切换

//取消请求
let pending = []; // 声明一个数组用于存储每个ajax请求的取消函数和ajax标识
let CancelToken = Axios.CancelToken;
// 取消请求 方法
let cancelPending = (config) => {
  pending.forEach((item, index) => {
    let flag = false;
    // cancelRequestArr包含的请求，重复请求 让他取消请求
    if (cancelRequestArr.includes(item.UrlPath)) {
      flag = true;
    }
    if (config) {
      if (item.UrlPath === config.url && flag) {
        item.Cancel(); // 取消请求
        pending.splice(index, 1); // 移除当前请求记录
      }
    }
  });
};

//设置默认请求头，如果每次请求都携带token 建议写在这里
Axios.defaults.headers = {};
Axios.defaults.withCredentials = true; // 默认为false  表示跨域请求时是否需要使用凭证
// 请求超时的时间限制
Axios.defaults.timeout = 20000;

// 开始设置请求 request 代表发起请求的参数的实体
Axios.interceptors.request.use(
  request => {
    try {
      //添加域名前缀
    if (/www.jsfan.net/g.test(window.location.host)) {
      request.baseURL = host[2].cloud;
      Axios.defaults.baseURL = host[2].cloud;
    } else if (/jsfan.net/g.test(window.location.host)) {
      //如果请求不带www的域名,则带www的cookie无法被发走
      request.baseURL = host[1].cloud;
      Axios.defaults.baseURL = host[1].cloud;
    } else {
      request.baseURL = "/blog-api"; //host[0].dev
      Axios.defaults.baseURL = "/blog-api"; //host[0].dev
    }
    //携带token
    const token = storageUtils.getUser().token;
    token&&(request.headers.Authorization = `${token}`);
    cancelPending(request);
    request.cancelToken = new CancelToken((res) => {
      pending.push({ UrlPath: request.url, Cancel: res });
    });
    } catch (error) {
      console.error('axios请求拦截出错');
      request.headers.Authorization = 'Bearer ';
    }
    return request;
  },
  error => {
    return Promise.reject(error);
  },
);

// 请求到结果的拦截处理
Axios.interceptors.response.use(
  (response = {}) => {
    // 返回请求正确的结果 解构出需要返回的数据
    const {data, status} = response;
    // 取消请求
    cancelPending(response.request);
    return {data, status};
  },
  error => {
    console.error("axios res显示：", error.message);
    if (error.message === "timeout of 20000ms exceeded") {
      error.message = "连接超时，请稍后重试";
    }
    const {response = {}} = error;
    if (response.status === 401 && !/login/g.test(window.location.href)) {
      Modal.warning({
        title: "身份校验已过期",
        content: "请重新登录",
        onOk() {
          //删除保存的user数据
          storageUtils.removeUser();
          memoryUtils.user = {};
          window.location.href = "/login";
        },
      });
    }
    // 直接返回后台返回的错误的请求结果 如果需要前端自定义请参考原帖
    return Promise.reject(error);
  },
);

export default Axios;
