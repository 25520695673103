import React, {Component} from "react";
import {Avatar, Divider, Tooltip, Icon} from "antd";
import "../style/components/author.css";
import Zmage from "react-zmage";
import store from "../store/index"; //redux使用
const AnimaImg = "https://www.jsfan.net/some/author/%E5%8D%A1%E5%93%87%E4%BC%8Anumber1.png";
const Authorimg = "https://www.jsfan.net/some/author/author.jpg";
let deg = 0;

class Author extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tencnet: false,
      wechat: false,
      imgurl: store.getState().defstyle?AnimaImg:Authorimg, //图片链接
      isAuthor: !store.getState().defstyle, //是否展示作者

      def: store.getState().defstyle,
    };
    store.subscribe(this.storeChange.bind(this)); //订阅Redux的状态
  }
  storeChange() {
    //引用redux中的值修改当前state
    this.setState({def: store.getState().defstyle});
  }

  componentDidMount() {
    var str = "JSFan,技术迷";
    var i = 0;
    var divTyping = document.getElementById("zz");
    function reduce() {
      if (i > 0) {
        divTyping.innerHTML = str.slice(0, i--);
        setTimeout(() => {
          reduce();
        }, 300);
      } else {
        i = 1;
        divTyping.innerHTML = "_";
        setTimeout(() => {
          typing();
        }, 300);
      }
    }
    function typing() {
      if (i <= str.length) {
        divTyping.innerHTML = str.slice(0, i++) + "_";
        setTimeout(() => {
          typing();
        }, 300);
      } else {
        i = str.length;
        setTimeout(() => {
          reduce();
        }, 1000);
      }
    }
    typing();
  }
  changeimg() {
    let img = document.getElementById("img");
    if (this.state.isAuthor) {
      deg -= 360;
      img.style.transform = "rotate(" + deg + "deg)";
      setTimeout(() => {
        this.setState({
          imgurl: AnimaImg,
          isAuthor: false,
        });
      }, 300);

      const action = {
        //redux 旋转改色
        type: "changestyle",
        value: true,
      };
      store.dispatch(action);
    } else {
      deg += 360;
      img.style.transform = "rotate(" + deg + "deg)";
      setTimeout(() => {
        this.setState({
          imgurl: Authorimg,
          isAuthor: true,
        });
      }, 300);
      const action = {
        //redux 旋转改色
        type: "changestyle",
        value: false,
      };
      store.dispatch(action);
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    return (
      <div className="author-div comm-box cssniceright" style={{backgroundColor: "rgba(255,255,255,0.4)"}}>
        <div>
          <Avatar
            size={100}
            src={this.state.imgurl}
            className="mylight"
            onMouseEnter={() => {
              this.changeimg();
            }}
            id="img"
          />
        </div>
        <div className="myname" style={this.state.def ? {color: "hotpink"} : {color: "rgba(0, 0, 0, 0.65)"}}>
          Youngster_yj
        </div>
        <div className="author-introduction" style={{color: "rgb(0,216,255)"}}>
          软件工程
        </div>
        <div className="author-introduction myname1" style={{color: "rgb(0,216,255)"}}>
          2017-2021级毕业生
        </div>
        <div className="author-introduction position">
          <Icon type="environment" /> 四川-达州
          <br />
          前端: React + Antd Design
          <br />
          后端: Node + Mongodb
          <br />
          <Icon type="mail" /> 907985037@qq.com
          <br />
          <span
            id="zz"
            style={
              this.state.def ? {color: "deeppink", fontWeight: "bold"} : {color: "#1890ff", fontWeight: "bold"}
            }></span>
          <Divider style={this.state.def ? {color: "hotpink", marginBottom: 0} : {marginBottom: 0}}>社交账号</Divider>
          <Tooltip placement="top" title={<span>GitHub</span>}>
            <a
              href="https://github.com/youngster-yj"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "inline-block",
                marginLeft: ".5rem",
                marginRight: ".5rem",
                marginTop: 16,
              }}>
              <Avatar
                size={28}
                icon="github"
                style={
                  this.state.def ? {color: "#fff", backgroundColor: "hotpink"} : {backgroundColor: "#999"}
                }></Avatar>
            </a>
          </Tooltip>
          <a
            href="tencent://message/?uin=907985037&Site=Sambow&Menu=yes"
            style={{display: "inline-block", marginTop: 16}}>
            <Avatar
              size={28}
              icon="qq"
              style={
                this.state.tencent
                  ? {color: "rgb(77,173,219)", backgroundColor: "#fff"}
                  : this.state.def
                  ? {color: "#fff", backgroundColor: "hotpink"}
                  : null
              }
              className="account"
              onMouseEnter={() => this.setState({tencent: true})}
              onMouseLeave={() => this.setState({tencent: false})}></Avatar>
          </a>
          <Avatar
            size={28}
            icon="wechat"
            style={
              this.state.wechat
                ? {color: "#fff", backgroundColor: "green"}
                : this.state.def
                ? {color: "#fff", backgroundColor: "hotpink"}
                : null
            }
            className="account"
            onMouseEnter={() => this.setState({wechat: true})}
            onMouseLeave={() => this.setState({wechat: false})}
            onClick={() => Zmage.browsing({src: "https://www.jsfan.net/upload/wechat.png"})}></Avatar>
          <Tooltip placement="top" title={<span>CSND</span>}>
            <a
              href="https://blog.csdn.net/Youngster_yj"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "inline-block",
                marginLeft: ".5rem",
                marginRight: ".5rem",
                marginTop: 16,
              }}>
              <Avatar
                size={28}
                icon="link"
                style={
                  this.state.def ? {color: "#fff", backgroundColor: "hotpink"} : {backgroundColor: "#999"}
                }></Avatar>
            </a>
          </Tooltip>
          {this.state.tencent ? (
            <div className="tencent">
              <div>
                <img
                  src="https://www.jsfan.net/upload/qrcode_1581592204285.jpg"
                  style={{width: 100}}
                  alt="图片加载失败"
                />
              </div>
            </div>
          ) : null}
          {this.state.wechat ? (
            <div className="wechat">
              <div>
                <img
                  src="https://www.jsfan.net/upload/wechat.png"
                  style={{minWidth: 100, width: 100}}
                  alt="图片加载失败"
                />
                <div style={{color: "black", height: 30, fontWeight: "bold"}}>
                  <span>Yougster_降头师</span>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Author;
