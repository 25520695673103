import React, {Component} from "react";
import {Avatar, Divider, Tooltip, Tag, Button, Modal, Input, Icon, message} from "antd";
import "../style/components/author.css";
import "../style/components/drawer.css";
import servicePath from "../config/apiUrl";
import Zmage from "react-zmage";
import store from "../store/index"; //redux使用
const AnimaImg = "https://www.jsfan.net/some/author/%E5%8D%A1%E5%93%87%E4%BC%8Anumber1.png";
const Authorimg = "https://www.jsfan.net/some/author/author.jpg";
let deg = 0;

class Drawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tencent: false,
      wechat: false,

      friendsLink: [],

      visible: false,

      qqemail: "",
      weburl: "",
      webname: "",
      webinfo: "",
      webimgurl: "",

      imgurl: AnimaImg, //图片链接
      isAuthor: false, //是否展示作者

      def: store.getState().defstyle,
    };
    store.subscribe(this.storeChange.bind(this)); //订阅Redux的状态
  }
  storeChange() {
    //引用redux中的值修改当前state
    this.setState({def: store.getState().defstyle});
  }
  componentDidMount() {
    fetch(servicePath.getPassFriendLink, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then(res => res.json())
      .then(data => {
        //获取置顶文章并重新排序
        let top = data.filter(item => {
          return item.toplink == true;
        });
        let notop = data.filter(item => {
          return item.toplink != true;
        });
        let add = notop.reverse();
        top.map(item => {
          add.push(item);
        });
        let add2 = add.reverse();
        // console.log(add2) //新的排序

        this.setState({friendsLink: add2});
      })
      .catch(error => {
        message.error("服务器端炸裂" + error);
      });

    let antstyle = document.getElementsByClassName("ant-drawer-content")[0];
    this.state.def ? (antstyle.style.backgroundColor = "pink") : (antstyle.style.backgroundColor = "rgb(40,54,70)");
  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = e => {
    let webname = this.state.webname;
    let weburl = this.state.weburl;
    let qqemail = this.state.qqemail;
    let webinfo = this.state.webinfo;
    let webimgurl = this.state.webimgurl;
    if (webname.length != 0 && weburl.length != 0 && qqemail.length != 0) {
      fetch(servicePath.saveFriendLink, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body:
          "webname=" +
          webname +
          "&weburl=" +
          weburl +
          "&qqemail=" +
          qqemail +
          "&webinfo=" +
          webinfo +
          "&webimgurl=" +
          webimgurl,
      })
        .then(res => res.json())
        .then(data => {
          // console.log(data)
          if (data == "存储成功") {
            message.success("提交成功,请等待");
            this.setState({
              visible: false,
            });
          } else {
            message.error("存储失败");
            this.setState({
              visible: false,
              webname: "",
              weburl: "",
              qqemail: "",
              webinfo: "",
              webimgurl: "",
            });
          }
        })
        .catch(error => {
          message.error("服务器端炸裂" + error);
        });
    } else {
      message.warning("必填项不可为空");
    }
  };
  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  changeimg() {
    //头像旋转
    let img = document.getElementById("imgg");
    if (this.state.isAuthor) {
      deg -= 360;
      img.style.transform = "rotate(" + deg + "deg)";
      setTimeout(() => {
        this.setState({
          imgurl: AnimaImg,
          isAuthor: false,
        });
      }, 300);

      const action = {
        //redux 旋转改色
        type: "changestyle",
        value: true,
      };
      store.dispatch(action);
    } else {
      deg += 360;
      img.style.transform = "rotate(" + deg + "deg)";
      setTimeout(() => {
        this.setState({
          imgurl: Authorimg,
          isAuthor: true,
        });
      }, 300);
      const action = {
        //redux 旋转改色
        type: "changestyle",
        value: false,
      };
      store.dispatch(action);
    }
  }
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }
  componentDidUpdate() {
    let antstyle = document.getElementsByClassName("ant-drawer-content")[0];
    this.state.def ? (antstyle.style.backgroundColor = "pink") : (antstyle.style.backgroundColor = "rgb(40,54,70)");
  }
  render() {
    const {cip="",cname=""} = window?.cheng||{}
    return (
      <div style={{textAlign: "center"}}>
        <div
          className="author-div comm-box"
          style={
            this.state.def
              ? {border: 0, backgroundColor: "pink", paddingBottom: 0}
              : {border: 0, backgroundColor: "rgb(40,54,70)", paddingBottom: 0}
          }>
          <div>
            <Avatar
              size={100}
              src={this.state.imgurl}
              className="mylight"
              onClick={() => {
                this.changeimg();
              }}
              id="imgg"
            />
          </div>
          <div className="myname cssnice">Youngster_yj</div>
          <div
            className="author-introduction cssnice"
            style={this.state.def ? {color: "deeppink"} : {color: "rgb(0,216,255)"}}>
            软件工程
          </div>
          <div
            className="author-introduction myname1 cssnice"
            style={this.state.def ? {color: "deeppink"} : {color: "rgb(0,216,255)"}}>
            2017-2021级毕业生
          </div>
          <div className="author-introduction position cssnice" style={this.state.def ? {color: "#eee"} : null}>
            <Icon type="environment" /> 四川-达州
            <br />
            前端: React + Antd Design
            <br />
            后端: Node + Mongodb
            <br />
            <Icon type="mail" /> 907985037@qq.com
            <br />
            一个菜鸟,啥也不会
            <Divider style={{color: "rgba(255, 255, 255, .6)"}}>社交账号</Divider>
            <Tooltip placement="top" title={<span>https://github.com/youngster-yj</span>}>
              <a
                href="https://github.com/youngster-yj"
                target="_blank"
                rel="noopener noreferrer"
                style={{display: "inline-block", marginLeft: ".5rem", marginRight: ".5rem"}}>
                <Avatar
                  size={28}
                  icon="github"
                  style={
                    this.state.def ? {color: "#fff", backgroundColor: "hotpink"} : {backgroundColor: "#999"}
                  }></Avatar>
              </a>
            </Tooltip>
            <Avatar
              size={28}
              icon="qq"
              style={
                this.state.tencent
                  ? {color: "rgb(77,173,219)", backgroundColor: "#fff"}
                  : this.state.def
                  ? {color: "#fff", backgroundColor: "hotpink"}
                  : null
              }
              className="account"
              onMouseEnter={() => this.setState({tencent: true})}
              onMouseLeave={() => this.setState({tencent: false})}
              onClick={() => Zmage.browsing({src: "https://www.jsfan.net/upload/qrcode_1581592204285.jpg"})}></Avatar>
            <Avatar
              size={28}
              icon="wechat"
              style={
                this.state.wechat
                  ? {color: "#fff", backgroundColor: "green"}
                  : this.state.def
                  ? {color: "#fff", backgroundColor: "hotpink"}
                  : null
              }
              className="account"
              onMouseEnter={() => this.setState({wechat: true})}
              onMouseLeave={() => this.setState({wechat: false})}
              onClick={() => Zmage.browsing({src: "https://www.jsfan.net/upload/wechat.png"})}></Avatar>
            <Tooltip placement="top" title={<span>CSND</span>}>
              <a
                href="https://blog.csdn.net/Youngster_yj"
                target="_blank"
                rel="noopener noreferrer"
                style={{display: "inline-block", marginLeft: ".5rem", marginRight: ".5rem", marginTop: 16}}>
                <Avatar
                  size={28}
                  icon="link"
                  style={
                    this.state.def ? {color: "#fff", backgroundColor: "hotpink"} : {backgroundColor: "#999"}
                  }></Avatar>
              </a>
            </Tooltip>
          </div>

          <div style={{textAlign: "center", marginTop: "8px"}}>
            <div style={this.state.def ? {fontSize: ".7rem", color: "#eee"} : {fontSize: ".7rem", color: "#999"}}>
              <div style={{fontSize: "1rem"}}>访客信息</div>
              <div>
                欢迎来自
                <span style={this.state.def ? {color: "deeppink"} : {color: "rgb(0, 216, 255)"}}>
                  {cname}
                </span>
                的访问者
              </div>
              <div>
                您于
                <span style={this.state.def ? {color: "deeppink"} : {color: "rgb(0, 216, 255)"}}>
                  {window.time.year}年{window.time.month}月{window.time.date}日 {window.time.hours}:
                  {window.time.minutes}
                </span>
                访问
              </div>
              <div>
                ip:
                <span style={this.state.def ? {color: "deeppink"} : {color: "rgb(0, 216, 255)"}}>
                  {cip}
                </span>
                (密)
              </div>
              <div>
                使用
                <span style={this.state.def ? {color: "deeppink"} : {color: "rgb(0, 216, 255)"}}>{window.device}</span>
                系统
              </div>
              <div>
                基于
                <span style={this.state.def ? {color: "deeppink"} : {color: "rgb(0, 216, 255)", fontSize: ".55rem"}}>
                  {window.liulanqi.type}({window.liulanqi.version})
                </span>
                内核
              </div>
            </div>
          </div>
        </div>

        <div className="friendsLink" style={this.state.def ? {color: "deeppink"} : {fontSize: "1rem"}}>
          友情链接
        </div>
        {this.state.friendsLink.map((item, index) =>
          item.agree == true ? (
            item.isclick || item.isclick == undefined ? (
              <a key={index} target="_blank" rel="noopener noreferrer" href={item.weburl}>
                <Tag color="volcano">{item.webname}</Tag>
              </a>
            ) : (
              <Tag
                key={index}
                color="red"
                onClick={() => {
                  message.warning("此博客作者暂未开发完毕");
                }}>
                {item.webname}
              </Tag>
            )
          ) : null,
        )}
        <div>
          <Button size="small" onClick={this.showModal} style={this.state.def ? {color: "deeppink"} : null}>
            友链提交
          </Button>
        </div>

        <Modal
          title="申请友链"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          okText="立即申请"
          cancelText="取消"
          maskClosable={false}
          centered={true}>
          <Input
            onChange={e => this.setState({webname: e.target.value})}
            style={{marginBottom: "1rem"}}
            placeholder="网站名称(必填)"
            prefix={<Icon type="user-add" style={{color: "rgba(0,0,0,.25)"}} />}
            suffix={
              <Tooltip title="网站名称必须填写">
                <Icon type="info-circle" style={{color: "rgba(0,0,0,.45)"}} />
              </Tooltip>
            }
          />
          <Input
            onChange={e => this.setState({qqemail: e.target.value})}
            style={{marginBottom: "1rem"}}
            placeholder="QQ邮箱(必填)"
            prefix={<Icon type="mail" style={{color: "rgba(0,0,0,.25)"}} />}
            suffix={
              <Tooltip title="邮箱地址必须填写">
                <Icon type="info-circle" style={{color: "rgba(0,0,0,.45)"}} />
              </Tooltip>
            }
          />
          <Input
            onChange={e => this.setState({weburl: e.target.value})}
            style={{marginBottom: "1rem"}}
            placeholder="http(s)://开始"
            prefix={<Icon type="ie" style={{color: "rgba(0,0,0,.25)"}} />}
            suffix={
              <Tooltip title="网站地址必须填写">
                <Icon type="info-circle" style={{color: "rgba(0,0,0,.45)"}} />
              </Tooltip>
            }
          />

          <Input
            onChange={e => this.setState({webinfo: e.target.value})}
            style={{marginBottom: "1rem"}}
            placeholder="大佬的网站介绍"
            prefix={<Icon type="form" style={{color: "rgba(0,0,0,.25)"}} />}
            suffix={
              <Tooltip title="网站介绍(可选)">
                <Icon type="question" style={{color: "rgba(0,0,0,.45)"}} />
              </Tooltip>
            }
          />
          <Input
            onChange={e => this.setState({webimgurl: e.target.value})}
            style={{marginBottom: "1rem"}}
            placeholder="网站头像地址(http(s)://开始)"
            prefix={<Icon type="robot" style={{color: "rgba(0,0,0,.25)"}} />}
            suffix={
              <Tooltip title="头像地址(可选)">
                <Icon type="question" style={{color: "rgba(0,0,0,.45)"}} />
              </Tooltip>
            }
          />
          <div style={{marginBottom: "1rem", textAlign: "right", fontSize: ".7rem"}}>
            <a target="_blank" rel="noopener noreferrer" href="https://www.jsfan.net/upload/jsfan博客头像.jpg">
              本博客头像URL
            </a>
          </div>
          <div style={{marginBottom: "1rem", textAlign: "right", fontSize: ".7rem"}}>
            PS：立即申请后请等待QQ邮箱通知
          </div>
        </Modal>
      </div>
    );
  }
}

export default Drawer;
