import React, {Component} from "react";
import Header from "./components/Header";
import "./style/components/picture.less";
import {Row, Col, Icon, BackTop} from "antd";
import Zmage from "react-zmage";
const imgTotal = 74; //共有74张图
let loadCount = 0; //第几次加载图片
class Picture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      beginload: 38, //初始加载图片张数
      isload: 1, //每次加载isload张图片(后续每次加载)
    };
  }
  setData(beginload) {
    var arr = new Array(beginload); //共有74张 因拉取性能问题 减少初始拉取图片张数
    for (var i = 0; i < arr.length; i++) {
      arr[i] = i;
    }
    this.setState({
      data: arr,
    });
  }
  componentWillMount() {
    let beginload = 24; //初始加载图片张数
    let isload = 1; //每次加载isload张图片(后续每次加载)
    const {clientWidth} = document.body;
    // 根据分辨率选择初始加载图片数
    if (clientWidth > 1750) {
      beginload = 28;
    } else if (clientWidth <= 1750 && clientWidth > 1400) {
      beginload = 28;
      //   isload = 1;
    } else if (clientWidth <= 1400 && clientWidth > 800) {
      beginload = 24;
    } else if (clientWidth <= 800 && clientWidth > 400) {
      beginload = 12;
      isload = 4;
    } else if (clientWidth <= 400) {
      beginload = 6;
    }
    this.setState(
      {
        beginload,
        isload,
      },
      () => {
        this.setData(beginload);
      },
    );

    document.addEventListener("visibilitychange", function () {
      var isHidden = document.hidden;
      if (isHidden) {
        document.title = '404!!!页面丢失(￣▽￣)"';
      } else {
        document.title = "嘤嘤嘤，你回来了啊(ಥ _ ಥ)";
        setTimeout(() => {
          document.title = "图库页 | Youngster_yj的学习记录";
        }, 3000);
      }
    });
    document.title = "图库页 | Youngster_yj的学习记录";
  }
  domPull() {
    const main = document.getElementById("main");
    const box = document.getElementsByClassName("box");
    const {isload, beginload} = this.state; //每次加载图片数 初始加载图片数
    const that = this;
    //实现瀑布流布局
    this.waterFull(main, box);

    //加载数据
    var timer1 = null;
    window.addEventListener(
      "scroll",
      e => {
        clearTimeout(timer1);
        //节流
        timer1 = setTimeout(() => {
          if (this.checkWillLoadNewBox(main, box)) {
            //假数据模仿数据加载
            if (isload * (loadCount + 1) > imgTotal) {
              loadCount = 0;
            }
            let arr = new Array(isload); //共有74张 因拉取性能问题 减少每次加载图片张数 限制为每次拉取isload张
            for (let i = 0; i < arr.length; i++) {
              let j = 0; //当为第一次加载时累加
              if (loadCount === 0) j = beginload;
              arr[i] = isload * loadCount + i + j;
            }
            loadCount += 1;
            //遍历数据
            for (let i = 0; i < arr.length; i++) {
              var newBox = document.createElement("div");
              newBox.className = "box animation";
              main.appendChild(newBox);

              var newPic = document.createElement("div");
              newPic.className = "pic";
              newBox.appendChild(newPic);

              var newImg = document.createElement("img");
              newImg.src = "https://www.jsfan.net/some/lifeimg/life%20(" + arr[i] + ").jpg";
              newImg.addEventListener("click", () => {
                Zmage.browsing({src: "https://www.jsfan.net/some/lifeimg/life%20(" + arr[i] + ").jpg"});
              });
              newPic.appendChild(newImg);
            }
            //重新进行瀑布流布局
            this.waterFull(main, box);
          }
        });
      },
      200,
    );

    //页面尺寸发生改变重新布局
    var timer2 = null;
    window.addEventListener("resize", function () {
      clearTimeout(timer2);
      //节流
      timer2 = setTimeout(function () {
        that.waterFull(main, box);
      }, 200);
    });
  }
  waterFull(parent, child) {
    //(1)父盒子居中
    //1.1 获取所有子盒子
    //1.2 获取其中一个子盒子的宽度
    try {
      var boxWidth = child[0].offsetWidth;
    } catch (error) {
      return;
    }

    //1.3 获取窗口的宽度
    // var screenW = document.body.clientWidth
    //1.3 获取col的宽度
    var coldom = document.getElementsByClassName("comm-left")[0];
    var screenW = coldom.offsetWidth;
    // console.log(screenW)
    //1.4 求出列数
    var cols = parseInt(screenW / boxWidth);
    // console.log(cols)
    //1.5 父盒子居中
    parent.style.width = cols * boxWidth + "px";
    parent.style.margin = "0 auto";

    //(2)子盒子定位
    //2.1 定义变量
    var heightArr = [];
    var boxHeight = 0;
    var minBoxHeight = 0;
    //2.2 遍历所有盒子获取高度
    for (let i = 0; i < child.length; i++) {
      boxHeight = child[i].offsetHeight;
      //2.3 判断是否为第一行
      if (i < cols) {
        heightArr.push(boxHeight);
        child[i].style = ""; //(响应式布局)保证第一行没有添加样式
      } else {
        //剩余行做定位
        //2.4 取出数组中最矮盒子的高度
        let arrSort = heightArr.slice(0); //sort排序后，会影响原始数组 解决方案
        arrSort.sort(function (a, b) {
          return a - b;
        });
        // console.log(arrSort[0]) //最小
        //console.log(arrSort[arrSort.length-1]) //最大
        minBoxHeight = arrSort[0];
        //2.5 取出最矮盒子在数组中的索引
        var minBoxIndex = this.getMinBoxIndex(heightArr, minBoxHeight);
        // console.log(minBoxIndex)
        //2.6 剩余子盒子的定位
        child[i].style.position = "absolute";
        child[i].style.left = minBoxIndex * boxWidth + "px";
        child[i].style.top = minBoxHeight + "px";
      }
      //2.7 更新高度
      heightArr[minBoxIndex] += boxHeight;
    }

    // console.log(heightArr, minBoxHeight)
    let arrSortmax = heightArr.slice(0); //sort排序后，会影响原始数组 解决方案
    arrSortmax.sort(function (a, b) {
      return a - b;
    });
    // console.log(arrSortmax[arrSortmax.length-1]) //最大
    coldom.style.height = arrSortmax[arrSortmax.length - 1] + "px";
  }
  getMinBoxIndex(arr, val) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] === val) {
        return i;
      }
    }
  }
  checkWillLoadNewBox(parent, child) {
    //1. 获取最后的盒子
    var lastBox = child[child.length - 1];
    //2. 求最后盒子高度的一半
    try {
      var lastBoxDis = lastBox.offsetHeight * 0.5 + lastBox.offsetTop;
    } catch (error) {
      return;
    }

    //3. 求出页面高度
    var screenH = document.body.clientHeight || document.documentElement.clientHeight;
    //4. 求出页面滚出浏览器高度
    var scrollTopH = document.documentElement.scrollTop;
    //5. 返回结果
    return lastBoxDis <= screenH + scrollTopH;
  }
  load() {
    this.domPull();
  }
  render() {
    return (
      <div className="picture">
        <Header />

        <BackTop>
          <div className="ant-back-top-inner">
            <Icon type="rocket" />
          </div>
        </BackTop>

        <Row className="comm-main" type="flex" justify="center" style={{paddingTop: "2.7rem"}}>
          <Col
            className="comm-left"
            xs={24}
            sm={24}
            md={23}
            lg={23}
            xl={18}
            style={{padding: 0, backgroundColor: "rgba(255,255,255,.4)"}}>
            <div id="main">
              {this.state.data.map((item, key) => (
                <div className="box" key={key}>
                  <div className="pic">
                    <div className="suofanga" style={{overflow: "hidden"}}>
                      <img
                        className="divimg"
                        src={"https://www.jsfan.net/some/lifeimg/life%20(" + item + ").jpg"}
                        onLoad={() => this.load()}
                        onClick={() =>
                          Zmage.browsing({src: "https://www.jsfan.net/some/lifeimg/life%20(" + item + ").jpg"})
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Picture;
