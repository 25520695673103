import React from "react";
import {notification, Icon} from "antd";
import store from "@/store/index"; //redux使用
let def = store.getState().defstyle;
store.subscribe(() => {
  def = store.getState().defstyle;
});
const notifiFunction = (num, local) => {
  notification.open({
    message: `贵宾大大您好`,
    description: `欢迎来自 ${local} 的贵宾大大拜访本网站，当前同时在线人数 ${num} 位`,
    icon: <Icon type="smile" style={def ? {color: "pink"} : {color: "rgb(40, 54, 70)"}} />,
  });
};
const notifiFunctionInfo = (num, local) => {
  notification.open({
    message: `当前同时在线人数 ${num} 位`,
    description: `来自 ${local} 的访客拜访本网站`,
    icon: <Icon type="plus" style={def ? {color: "pink"} : {color: "rgb(40, 54, 70)"}} />,
  });
};

export {notifiFunction, notifiFunctionInfo};
