import {get} from "lodash";
import {notifiFunction, notifiFunctionInfo} from "@/common/notifiInfo.js";
import socketInit from "@/utils/socket.js";
const socket = socketInit("/welcome");
const Socket = () => {
  // 不间断尝试重连接
  socket.on("reconnect_attempt", () => {
    console.log("重新连接");
    socket.transports = ["websocket", "polling", "flashsocket"];
  });

  // 重连接时出错
  socket.on("reconnect_error", attemptNumber => {
    console.log("重连错误", attemptNumber);
  });

  //连接成功走这个方法
  socket.on("connect", () => {
    // console.log("连接成功", socket.connected);
    // socket.emit("join", prompt("请输入你的绰号"));
    const cname = get(window, "cheng", null);
    //连接计数器 发送地理位置
    socket.emit("join", cname);

    //网站在线人数
    socket.once("WebNum", (num, local) => {
      //socket.once()确保绑定到套接字的事件监听器的数量与注册的事件'Quest'正好相同
      console.log(num, local);
      notifiFunction(num, local);
    });

    //新人访问消息提示框
    socket.once("WebWelcomeInfo", (num, local) => {
      console.log(num, local);
      notifiFunctionInfo(num, local);
    });
  });

  //报错时走这个方法
  socket.on("connect_error", error => {
    console.log("socket错误", error);
  });

  //连接存活验证
  socket.on("ping", error => {
    console.log("连接存活验证");
  });
};

export default Socket;
