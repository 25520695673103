//使用socket.io
import io from "socket.io-client";
let ipUrl = "http://localhost:3000";
if (/jsfan.net/g.test(window.location.host)) {
  ipUrl = "https://www.jsfan.net";
}
//建立连接
const socket = (path = "", url = ipUrl) =>
  io(url, {
    path: path,
    transports: ["websocket"],
  });

export default socket;
