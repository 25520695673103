/*
包含n个日期时间处理的工具函数模块
*/

/*
  格式化日期
*/
export function formateDate(time) {
    if (!time) return ''
    let date = new Date(time)
    let seconds = date.getSeconds()
    if(seconds<10){
      seconds = "0"+seconds
    }
    
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
      + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + seconds
}

/*
  查找特定cookie
*/
export function getCookie(cookie_name) {
  var allcookies = document.cookie;
  //索引长度，开始索引的位置
  var cookie_pos = allcookies.indexOf(cookie_name);
  var value
  // 如果找到了索引，就代表cookie存在,否则不存在
  if (cookie_pos !== -1) {
      // 把cookie_pos放在值的开始，只要给值加1即可
      //计算取cookie值得开始索引，加的1为“=”
      cookie_pos = cookie_pos + cookie_name.length + 1;
      //计算取cookie值得结束索引
      var cookie_end = allcookies.indexOf(";", cookie_pos);

      if (cookie_end === -1) {
          cookie_end = allcookies.length;

      }
      //得到想要的cookie的值
      value = unescape(allcookies.substring(cookie_pos, cookie_end));
  }
  else {
      value = null
  }
  return value;
}