import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import axios from "@/utils/request";
import {message} from "antd";
import moment from "moment";
import ThemeContext from "@/context/index";
import memoryUtils from "@/utils/memoryUtils.js";
import storageUtils from "@/utils/storageUtils.js";
//读取local中保存user，保存到内存中
const user = storageUtils.getUser();
memoryUtils.user = user;

class Init extends React.Component {
  state = {
    lanternIsShow: false,
    theme: "light", //context数据
    loading: true,
  };
  componentDidMount() {
    this.initBlog();
  }
  initBlog = async () => {
    //博客初始化数据请求
    try {
      const {data} = await axios.get("/admin/platformSet/getData"); //,{params: obj}
      if (data.status === "success") {
        const init = data.data[0];
        window.initBlog = Object.freeze(init);
        this.console(init.platformUpdate);
        this.setState({lanternIsShow: init.lanternIsShow, loading: false});
      } else {
        message.error(data.msg);
      }
    } catch (error) {
      const {message: msg = "数据获取失败"} = error;
      message.error(msg);
    }
  };
  console = time => {
    //控制台输出
    const cons = console;
    if (cons) {
      const consoleLogDate = moment(time).format("YYYY-MM-DD HH:mm:ss");
      const consoleLogColora = "background:rgb(40,54,70); color: #fff; border-radius: 3px 0 0 3px;";
      const consoleLogColorb = "background:rgb(241, 131, 181) ; color: #fff; border-radius: 0 3px 3px 0;";
      cons.log(`%c 博客最新更新时间: %c ${consoleLogDate} `, consoleLogColora, consoleLogColorb);
      cons.log(
        "%c ",
        "background:url(https://img.xiaoyou66.com/images/2019/07/16/UMcH.jpg) no-repeat center;background-size:cover;padding-left:100%;padding-bottom:55%;overflow:hidden;border-radius:10px;margin:5px 0",
      );
      cons.log(`%c 博主公告: %c 本站内容仅供学习交流,请勿用于任何形式商业行为 `, consoleLogColora, consoleLogColorb);
      cons.log(`%c PS: %c 如有借用记得留言点赞哦!!! `, consoleLogColora, consoleLogColorb);
    }
    this.disConsole();
  };
  disConsole = () => {
    //关闭输出
    if (/jsfan.net/g.test(window.location.host)) {
      window.console = {
        log: () => {
          return null;
        },
        info: () => {
          return null;
        },
        warn: () => {
          return null;
        },
        error: () => {
          return null;
        },
      };
    }
  };
  render() {
    const {lanternIsShow, theme, loading} = this.state;
    return (
      <ThemeContext.Provider
        value={{
          theme,
          changeName: changeValue => {
            this.setState({
              theme: changeValue,
            });
          },
        }}>
        {!loading && <App lanternIsShow={lanternIsShow} />}
      </ThemeContext.Provider>
    );
  }
}

ReactDOM.render(<Init />, document.getElementById("root"));
