import axios from "@/utils/request";
import {message} from "antd";
import {get} from "lodash";
import api from "./api";
// 获取表格数据函数
const getTableData = async obj => {
  try {
    const {data} = await axios.get(api.getTableData, {params: obj});
    let code = get(data, "code", "");
    let msg = get(data, "message", "");
    if (code === 1) {
      return data.data;
    } else {
      message.error(msg);
    }
  } catch (error) {
    console.error(error);
    let msg = get(error.response, "data.msg", "");
    if (msg) {
      message.error(msg);
    }
  }
};

export {getTableData};
