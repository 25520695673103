import React from "react";
import "./Lantern.less";
const Lantern = () => {
  const {lanternOne, lanternTwo} = window.initBlog;
  return (
    <div className="lantern">
      <div class="deng-box">
        <div class="deng">
          <div class="xian"></div>
          <div class="deng-a">
            <div class="deng-b">
              <div class="deng-t">{lanternTwo ? lanternTwo : "佳节"}</div>
            </div>
          </div>
          <div class="shui shui-a">
            <div class="shui-c"></div>
            <div class="shui-b"></div>
          </div>
        </div>
      </div>
      <div class="deng-box1">
        <div class="deng">
          <div class="xian"></div>
          <div class="deng-a">
            <div class="deng-b">
              <div class="deng-t">{lanternOne ? lanternOne : "喜迎"}</div>
            </div>
          </div>
          <div class="shui shui-a">
            <div class="shui-c"></div>
            <div class="shui-b"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lantern;
